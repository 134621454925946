import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";

import { DATA_STALE_TIME } from "~/config/constants";
import { ApplicationApi, FeeWaiverCodeApi, GetApi } from "~/api";

export function useApplication(applicationId?: string) {
  return useQuery({
    queryKey: ["applications", applicationId],
    queryFn: () => GetApi(ApplicationApi).getApplication(applicationId ?? ""),
    enabled: Boolean(applicationId),
    placeholderData: keepPreviousData,
    retry: false,
    staleTime: DATA_STALE_TIME,
  });
}

export function useApplicationDocumentChecklistItems(applicationId?: string) {
  return useQuery({
    queryKey: ["applications", applicationId, "documents"],
    queryFn: () => GetApi(ApplicationApi).getApplicationDocuments(applicationId ?? ""),
    enabled: Boolean(applicationId),
    placeholderData: keepPreviousData,
    retry: false,
    staleTime: DATA_STALE_TIME,
  });
}

export function useVerifyFeeWaiverCode(code?: string) {
  return useQuery({
    queryKey: ["verifyFeeWaiverCode", code],
    queryFn: () =>
      code
        ? GetApi(FeeWaiverCodeApi).getFeeWaiverCode(code)
        : Promise.reject(new Error("code is required")),
    enabled: false,
    retry: false,
  });
}

export function useUpdateFeeWaiverCode(applicationId?: string) {
  return useMutation({
    mutationKey: ["updateFeeWaiverCode", applicationId],
    mutationFn: (code: string) =>
      GetApi(ApplicationApi).updateFeeWaiverCode(applicationId ?? "", {
        feeWaiverCode: code,
      }),
  });
}
