/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { IEnglishTestDetails, IErrorResponse } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class EnglishTestVerificationApi<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags English Test Verification API
   * @name GetEnglishTestResults
   * @summary Get the results of a given English test based on the supplied test ID.
   * @request GET:/api/english-test-verification/{testId}
   * @response `200` `IEnglishTestDetails`
   * @response `401` `IErrorResponse` Not signed in.
   * @response `404` `IErrorResponse` The test ID does not exist.
   */
  getEnglishTestResults = (
    testId: string,
    query: {
      /** If true, the response will include a base64-encoded photo. */
      includePhoto?: boolean;
      /** The provider of the test being verified. */
      testType: "IELTS";
    },
    params: RequestParams = {},
  ) =>
    this.request<IEnglishTestDetails, IErrorResponse>({
      path: `/api/english-test-verification/${testId}`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
}
