import { useState } from "react";
import { z } from "zod";
import {
  Grid,
  Link,
  MenuItem,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Alert,
  AlertProps,
} from "@mui/material";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";

import {
  CampusLocation,
  CodeStringNullableSchema,
  DateStringSchema,
  parseDateString,
  SafeStringForProse,
  toDateString,
} from "@packages/types";

import { createAddIssue } from "~/utils";
import { IAdmissionTestType, IEnglishTestDetails } from "~/api";
import { useAdmissionTestTypes, useReferenceData } from "~/hooks/reference-data";
import { useGetEnglishTestDetails } from "~/hooks/english-test-verification";
import { ButtonLoadingSpinner } from "~/components/core/ButtonLoadingSpinner";
import { FormSection } from "~/components/form/FormSection";
import { FormRadioGroup } from "~/components/form/FormRadioGroup";
import { FormCheckbox } from "~/components/form/FormCheckbox";
import { FormCheckboxGroup } from "~/components/form/FormCheckbox/FormCheckboxGroup";
import { FormTextField } from "~/components/form/FormTextField";
import { FormSelect } from "~/components/form/FormSelect";

import { ApplicationFields } from "./useApplicationForm";

export interface EnglishLanguageProficiencyFormProps {
  control: Control<ApplicationFields>;
  setValue: UseFormSetValue<ApplicationFields>;
  disabled?: boolean;
  campusLocation: string;
}

export function EnglishLanguageProficiencyForm(
  props: EnglishLanguageProficiencyFormProps,
) {
  const { control, campusLocation, disabled, setValue } = props;

  const [
    englishCourse,
    haveSatEnglishTest,
    willSitEnglishTest,
    hasOtherDocumentation,
    crmEnglishTestId,

    // used for English Test Verification
    externalEnglishTestSourceChannel,
    externalEnglishTestId,
    firstName,
    lastName,
    dateOfBirth,
    previousName,
  ] = useWatch({
    control,
    name: [
      "coursePreferences.englishCourse",
      "englishProficiency.haveSatEnglishTest",
      "englishProficiency.willSitEnglishTest",
      "englishProficiency.hasOtherDocumentation",
      "englishProficiency.englishTest.id",
      "englishProficiency.sourceChannel",
      "englishProficiency.testId",
      "personalDetails.legalGivenNames",
      "personalDetails.legalFamilyNames",
      "personalDetails.dateOfBirth",
      "monashStudies.previousName",
    ],
  });

  const allAdmissionTests = useAdmissionTestTypes();
  const hadEnglishInstructionOptions = useReferenceData("EnglishLanguageProficiency");

  const admissionTests = allAdmissionTests.data?.filter((test) => !isLOITest(test));
  const loiTest = allAdmissionTests.data?.filter(isLOITest)[0]; // used for "The applicant has other documentation"

  const selectedTest = allAdmissionTests.data?.find((x) => x.id === crmEnglishTestId);
  const isLOITestSelected = selectedTest?.id === loiTest?.id;

  const testVerificationAvailable =
    haveSatEnglishTest && Boolean(selectedTest?.testVerificationDetails); // i.e. applicant has completed test AND test can be verified through integration
  const disableTestScoresManualEntry =
    testVerificationAvailable &&
    !(
      externalEnglishTestSourceChannel === "Manual" ||
      externalEnglishTestSourceChannel === ""
    );
  const [testVerificationStatus, setTestVerificationStatus] = useState<
    "default" | "requestError" | "verificationFailure" | "success"
  >("default");
  const englishTestDetails = useGetEnglishTestDetails(
    selectedTest?.testVerificationDetails?.testType,
    externalEnglishTestId,
  );

  function resetEnglishTest() {
    setValue("englishProficiency.englishTest", {
      id: "",
      dateAchieved: "",
      expectedDateOfCompletion: "",
      scores: [],
    });
    setValue("englishProficiency.otherDocumentation", "");
  }

  function setEnglishTestScores(test?: IAdmissionTestType) {
    if (!test) {
      setValue("englishProficiency.englishTest.scores", []);
      return;
    }
    if (test.scores.length === 0)
      setValue("englishProficiency.englishTest.scores", [{ value: "" }]);
    else
      setValue(
        "englishProficiency.englishTest.scores",
        test.scores.map((name) => ({ name, value: "" })),
      );
    if (haveSatEnglishTest && test.testVerificationDetails)
      setValue(
        "englishProficiency.sourceChannel",
        test.testVerificationDetails?.testType,
      );
  }

  function setLOITest() {
    setValue("englishProficiency.englishTest", {
      id: loiTest?.id ?? "",
      dateAchieved: toDateString(new Date()),
      expectedDateOfCompletion: "",
      scores: [],
    });
    setValue("englishProficiency.otherDocumentation", "");
  }

  const verifyTest = {
    "": () => false,
    Manual: () => false,
    IELTS: (data: IEnglishTestDetails) =>
      externalEnglishTestId === data.testNumber &&
      dateOfBirth === data.dateOfBirth &&
      firstName === data.firstName &&
      (lastName === data.lastName || previousName === data.lastName),
  };

  async function verifyEnglishTest() {
    if (testVerificationAvailable)
      setValue(
        "englishProficiency.sourceChannel",
        selectedTest?.testVerificationDetails?.testType ?? "Manual",
      );
    const { data, isError } = await englishTestDetails.refetch();
    if (isError) {
      setTestVerificationStatus("requestError");
      setEnglishTestScores(selectedTest);
      setValue("englishProficiency.sourceChannel", "Manual");
    }
    if (data) {
      //verify test details before updating scores
      if (verifyTest[selectedTest?.testVerificationDetails?.testType ?? "Manual"](data)) {
        const {
          listeningScore,
          readingScore,
          speakingScore,
          writingScore,
          overallScore,
        } = data;
        setValue("englishProficiency.englishTest.scores", [
          {
            name: "Listening",
            value: String(listeningScore),
          },
          {
            name: "Reading",
            value: String(readingScore),
          },
          {
            name: "Speaking",
            value: String(speakingScore),
          },
          {
            name: "Writing",
            value: String(writingScore),
          },
          { value: String(overallScore) },
        ]);
        setTestVerificationStatus("success");
      } else {
        setTestVerificationStatus("verificationFailure");
        setEnglishTestScores(selectedTest);
        setValue("englishProficiency.sourceChannel", "Manual");
      }
    }
  }

  return (
    <>
      <FormSection.Well>
        <Typography variant="body1" paragraph>
          To study at Monash University, it's essential to have a strong command of
          English. Applicants must meet specific{" "}
          <Link
            href="http://policy.monash.edu/policy-bank/academic/education/admissions/admissions-coursework-courses-units-of-study-procedures.html"
            target="_blank"
            rel="noopener"
          >
            minimum English language proficiency requirements
          </Link>{" "}
          to enrol in courses at the university.
        </Typography>
        <Typography variant="body1">
          Monash University will determine if the applicant satisfies the English
          requirement (including time limitation) for the applicant's preferred course.
        </Typography>
      </FormSection.Well>
      <Stack gap={2}>
        <FormRadioGroup
          control={control}
          name="englishProficiency.hadEnglishInstruction.code"
          disabled={disabled}
          label={
            <>
              Has the applicant completed at least six years of schooling in an English
              medium institution in an{" "}
              <Link
                href="https://www.monash.edu/admissions/entry-requirements/english-language"
                target="_blank"
                rel="noopener"
              >
                English-speaking country
              </Link>{" "}
              prior to the age of 19?
            </>
          }
          loading={hadEnglishInstructionOptions.isPending}
          options={hadEnglishInstructionOptions.data}
        />
        <FormCheckboxGroup
          control={control}
          name="englishProficiency"
          disabled={disabled}
          label="English language proficiency test"
        >
          <FormCheckbox
            control={control}
            name="englishProficiency.haveSatEnglishTest"
            label="The applicant has completed a test"
            onBeforeChange={(_, checked) => {
              if (checked) {
                setValue("englishProficiency.willSitEnglishTest", false);
                setValue("englishProficiency.hasOtherDocumentation", false);
                setValue("englishProficiency.englishTest.expectedDateOfCompletion", "");
                if (isLOITestSelected) resetEnglishTest();
                else setEnglishTestScores(selectedTest);
              } else if (!willSitEnglishTest || hasOtherDocumentation) resetEnglishTest();
            }}
          />
          <FormCheckbox
            control={control}
            name="englishProficiency.willSitEnglishTest"
            label="The applicant will sit a test"
            onBeforeChange={(_, checked) => {
              if (checked) {
                setValue("englishProficiency.haveSatEnglishTest", false);
                setValue("englishProficiency.hasOtherDocumentation", false);
                setValue("englishProficiency.englishTest.dateAchieved", "");
                if (isLOITestSelected) resetEnglishTest();
                else setEnglishTestScores();
              } else if (!haveSatEnglishTest && !hasOtherDocumentation)
                resetEnglishTest();
            }}
          />
          {campusLocation !== CampusLocation.MALAYSIA && (
            <FormControlLabel
              label="The applicant has applied for a Monash English Language Centre course"
              control={<Checkbox checked={Boolean(englishCourse)} />}
              disabled
            />
          )}
          <FormCheckbox
            control={control}
            name="englishProficiency.hasOtherDocumentation"
            label="The applicant has other documentation"
            onBeforeChange={(_, checked) => {
              if (checked) {
                setValue("englishProficiency.haveSatEnglishTest", false);
                setValue("englishProficiency.willSitEnglishTest", false);
                setLOITest();
              } else resetEnglishTest();
            }}
          />
        </FormCheckboxGroup>
      </Stack>
      <Grid container spacing={2}>
        {(haveSatEnglishTest || willSitEnglishTest) && (
          <>
            <Grid item xs={12}>
              <FormSelect
                control={control}
                name="englishProficiency.englishTest.id"
                label="Test Type"
                loading={allAdmissionTests.isPending}
                disabled={disabled}
                onBeforeChange={(e) => {
                  const id = e.target.value;
                  const newTest = admissionTests?.find((x) => x.id === id);

                  // Unset automated english test verification values and return to default
                  setValue("englishProficiency.testId", "");
                  setValue(
                    "englishProficiency.sourceChannel",
                    newTest?.testVerificationDetails?.testType ?? "Manual",
                  );
                  setTestVerificationStatus("default");

                  // Don't need to set scores if the test has not been taken
                  if (!haveSatEnglishTest) return;
                  setEnglishTestScores(newTest);
                }}
              >
                {admissionTests?.map(({ id, description }) => (
                  <MenuItem key={id} value={id}>
                    {description}
                  </MenuItem>
                ))}
              </FormSelect>
            </Grid>
            {testVerificationAvailable && (
              <Grid item xs={12}>
                <Alert
                  severity={getTestVerificationAlertSeverity(testVerificationStatus)}
                >
                  {TEST_VERIFICATION_MESSAGES[testVerificationStatus]}
                </Alert>
              </Grid>
            )}
            {haveSatEnglishTest && (
              <Grid item xs={6}>
                <FormTextField
                  control={control}
                  name="englishProficiency.englishTest.dateAchieved"
                  label="Date achieved"
                  disabled={disabled}
                  type="date"
                  fullWidth
                />
              </Grid>
            )}
            {willSitEnglishTest && (
              <Grid item xs={6}>
                <FormTextField
                  control={control}
                  name="englishProficiency.englishTest.expectedDateOfCompletion"
                  label="Expected completion date"
                  disabled={disabled}
                  type="date"
                  fullWidth
                />
              </Grid>
            )}
            {(!haveSatEnglishTest || !willSitEnglishTest) &&
              !testVerificationAvailable && <Grid item xs={6} />}
            {testVerificationAvailable && (
              <Grid item xs={6}>
                <FormTextField
                  control={control}
                  name={`englishProficiency.testId`}
                  label="Test Report Form Number"
                  disabled={disabled}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <Button
                        variant="contained"
                        sx={{ px: englishTestDetails.isFetching ? 4.5 : 3.5 }}
                        disabled={
                          !externalEnglishTestId ||
                          englishTestDetails.isFetching ||
                          disabled
                        }
                        onClick={verifyEnglishTest}
                        endIcon={
                          <ButtonLoadingSpinner show={englishTestDetails.isFetching} />
                        }
                      >
                        Verify&nbsp;Test
                      </Button>
                    ),
                  }}
                />
              </Grid>
            )}
            {haveSatEnglishTest && selectedTest?.scores.length === 0 && (
              <Grid item xs={6}>
                <FormTextField
                  control={control}
                  name={`englishProficiency.englishTest.scores.0.value`}
                  label="Score"
                  disabled={disabled || disableTestScoresManualEntry}
                  fullWidth
                />
              </Grid>
            )}
            {haveSatEnglishTest &&
              selectedTest?.scores.map((name, index) => (
                <Grid item key={name ?? index} xs={6}>
                  <FormTextField
                    control={control}
                    name={`englishProficiency.englishTest.scores.${index}.value`}
                    label={name ? `${name} score` : "Score"}
                    disabled={disabled || disableTestScoresManualEntry}
                    fullWidth
                  />
                </Grid>
              ))}
          </>
        )}
        {hasOtherDocumentation && (
          <Grid item xs={12}>
            <FormTextField
              control={control}
              name="englishProficiency.otherDocumentation"
              label="Enter details for other documentation that satisfies English proficiency"
              multiline
              minRows={3}
              maxRows={12}
              disabled={disabled}
              fullWidth
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

/** Utility function to check if a given admission test is the LOI (Language of Instruction) Test. */
function isLOITest(test: IAdmissionTestType) {
  return test.description === "LOI (Language of Instruction)";
}

export const EnglishTestScoreSchema = z.object({
  name: z.string().optional(),
  value: z.coerce
    .number({ invalid_type_error: "Please provide a valid number" })
    .min(0, "Cannot be less than 0")
    .max(999, "Cannot be greater than 999")
    .transform((score) => score.toString()),
});

export const EnglishTestSchema = z.object({
  recordId: z.string().optional(),
  id: z.string(),
  dateAchieved: DateStringSchema.allowBlank(),
  expectedDateOfCompletion: DateStringSchema.allowBlank(),
  scores: z.array(EnglishTestScoreSchema),
});

EnglishLanguageProficiencyForm.draftSchema = z
  .object({
    hadEnglishInstruction: CodeStringNullableSchema,
    willSitEnglishTest: z.boolean(),
    haveSatEnglishTest: z.boolean(),
    englishTest: EnglishTestSchema,
    hasOtherDocumentation: z.boolean(),
    otherDocumentation: SafeStringForProse.schema(),

    // TODO: update schema as new fields become available
    sourceChannel: z.enum(["Manual", "IELTS", ""]),
    testId: z.string(),
    monashEnglishProgramPreference: z.enum(["Yes", "No", ""]),
  })
  .superRefine(({ willSitEnglishTest, haveSatEnglishTest, englishTest }, ctx) => {
    const addIssue = createAddIssue(ctx);

    if (willSitEnglishTest || haveSatEnglishTest) {
      if (!englishTest.id) addIssue("Please select a test type", "englishTest.id");

      if (haveSatEnglishTest) {
        if (!englishTest.dateAchieved)
          addIssue(
            "Please provide the date the test score was achieved",
            "englishTest.dateAchieved",
          );
        else {
          const date = parseDateString(englishTest.dateAchieved);
          if (date.getTime() > Date.now())
            addIssue("Date achieved must be in the past", "englishTest.conferredDate");
        }

        englishTest.scores.forEach((score, index) => {
          if (!score.value)
            addIssue("Please provide a score", `englishTest.scores.${index}.value`);
        });
      }

      if (willSitEnglishTest) {
        if (!englishTest.expectedDateOfCompletion)
          addIssue(
            "Please provide the expected completion date",
            "englishTest.expectedDateOfCompletion",
          );
        else {
          const date = parseDateString(englishTest.expectedDateOfCompletion);
          if (date.getTime() < Date.now())
            addIssue(
              "Expected date of completion must be in the future",
              "englishTest.expectedDateOfCompletion",
            );
        }
      }
    }
  });

EnglishLanguageProficiencyForm.submitSchema =
  EnglishLanguageProficiencyForm.draftSchema.superRefine(
    (
      {
        hadEnglishInstruction,
        hasOtherDocumentation,
        otherDocumentation,
        willSitEnglishTest,
        haveSatEnglishTest,
        sourceChannel,
        testId,
      },
      ctx,
    ) => {
      const addIssue = createAddIssue(ctx);

      if (!hadEnglishInstruction?.code)
        addIssue("Please select an answer", "hadEnglishInstruction.code");

      if (!(willSitEnglishTest || haveSatEnglishTest || hasOtherDocumentation))
        addIssue("Please select one of these three options", "englishTest");

      if (hasOtherDocumentation && !otherDocumentation)
        addIssue("Please provide documentation", "otherDocumentation");

      if (haveSatEnglishTest && sourceChannel === "IELTS" && !testId)
        addIssue("Please verify the applicant's English test", "testId");
    },
  );

const TEST_VERIFICATION_MESSAGES = {
  default: (
    <>
      For this test type, simply enter the applicant's Test Report Form Number and click
      the "Verify Test" button. The applicant's test scores will be fetched from the test
      provider and automatically filled into the form.
    </>
  ),
  requestError: (
    <>
      We were unable to fetch the details for this test ID. Please try again or manually
      enter the applicant's test scores below. Remember to also upload the applicant's
      English test result in the Supporting Documents section.
    </>
  ),
  verificationFailure: (
    <>
      English test verification unsuccessful. Check the information you have entered below
      and try again or manually enter the applicant's test scores below. Remember to also
      upload the applicant's English test result in the Supporting Documents section.
    </>
  ),
  success: (
    <>
      English test verification successful. Remember to also upload the applicant's
      English test results in the Supporting Documents section.
    </>
  ),
};

function getTestVerificationAlertSeverity(
  status: "default" | "requestError" | "verificationFailure" | "success",
): AlertProps["severity"] {
  switch (status) {
    case "success":
      return "success";
    case "requestError":
    case "verificationFailure":
      return "error";
    default:
      return "info";
  }
}
