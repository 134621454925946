import { useQuery } from "@tanstack/react-query";

import { EnglishTestVerificationApi, GetApi } from "~/api";

export function useGetEnglishTestDetails(
  testType?: "IELTS",
  testId?: string,
  includePhoto?: boolean,
) {
  return useQuery({
    queryKey: ["useGetEnglishTestDetails", testId],
    queryFn: () =>
      testId && testType
        ? GetApi(EnglishTestVerificationApi).getEnglishTestResults(testId, {
            testType,
            includePhoto,
          })
        : Promise.reject(new Error("testId and testType are required")),
    enabled: false,
    retry: false,
  });
}
